import request from '@/utils/request';

const PATH = '/payment';

export const getPaymentInfo = (paymentKey: any) => request({
  url: `${PATH}/find/${paymentKey}`,
  method: 'get',
});

export const getOrderId = (data: any) => request({
  url: `${PATH}/try`,
  method: 'post',
  data,
});

export const getDetail = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'get',
});
