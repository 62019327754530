
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getReservationClient } from '@/api/reservationClient';
import { getPaymentInfo } from '@/api/payment';
import moment from 'moment';
import { Dialog } from 'element-ui';

@Component({
  components: {
  },
})

export default class extends Vue {
  mounted() {
    this.getReservationClient();
  }

  private commonApiUrl = process.env.VUE_APP_COMMON_API;

  private apiUrl = process.env.VUE_APP_BASE_API;

  private loading = true;

  private modalVisible = false;

  // 다이얼로그의 가시성을 제어할 속성 추가
  private dialogVisible = false;

  private reservationClientInfo = {
    idx: '',
    version: '',
    paymentKey: '',
    status: '',
    lastTransactionKey: '',
    orderId: '',
    orderName: '',
    requestedAt: '',
    approvedAt: '',
    useEscrow: '',
    cultureExpense: '',
    card: {
      issuerCode: '',
      acquirerCode: 0,
      number: '',
      installmentPlanMonths: 0,
      isInterestFree: '',
      interestPayer: null,
      approveNo: '',
      useCardPoint: '',
      cardType: '',
      ownerType: '',
      acquireStatus: '',
      amount: 0,
    },
    virtualAccount: {
      accountNumber: '',
      accountType: '',
      bankCode: '',
      customerName: '',
      dueDate: '',
      expired: '',
      settlementStatus: '',
      refundStatus: '',
      refundReceiveAccount: {
        bank: '',
        accountNumber: '',
        holderName: '',
      },
    },
    transfer: {
      bankCode: '',
      settlementStatus: '',
    },
    mobilePhone: {
      customerMobilePhone: '',
      settlementStatus: '',
      receiptUrl: '',
    },
    giftCertificate: {
      approveNo: '',
      settlementStatus: '',
    },
    cashReceipt: {
      type: '',
      receiptKey: '',
      issueNumber: '',
      receiptUrl: '',
      amount: 0,
      taxFreeAmount: 0,
    },
    discountAmount: 0,
    secret: null,
    type: '',
    easyPay: {
      provider: '',
      amount: 0,
      discountAmount: 0,
    },
    country: '',
    failure: {
      provider: '',
      amount: '',
      discountAmount: '',
    },
    isPartialCancelable: true,
    receiptUrl: null,
    checkoutUrl: null,
    currency: '',
    totalAmount: 0,
    balanceAmount: 0,
    suppliedAmount: 0,
    vat: 0,
    taxFreeAmount: 0,
    method: '',
  };

  private reservationClient = {
    uid: '',
    name: '',
    phone: '',
    useDate: '',
    endDate: '',
    checkIn: '',
    checkOut: '',
    reservationCode: '',
    paymentDate: '',
    paymentType: '',
    paymentKey: '',
    usePoint: 0,
    useStatus: false,
    industryIdx: null,
    storeIdx: null,
    itemUid: '',
    storeName: '',
    itemName: '',
    storeThumbUid: '',
    method: '',
    memo: '',
  }

  private getReservationClient() {
    this.loading = true;
    getReservationClient(this.$route.params.uid).then((res) => {
      this.reservationClient = res.data;
      this.loading = false;
    });
  }

  private fewDays() {
    let fewDays = 1;
    const startDate = moment(this.reservationClient.useDate, 'YYYY-MM-DD');
    const endDate = moment(this.reservationClient.endDate, 'YYYY-MM-DD');
    fewDays = endDate.diff(startDate, 'days');
    return `${fewDays}박`;
  }

  private openModal() {
    this.modalVisible = true;
  }

  private closeModal() {
    this.modalVisible = false;
  }

  private goBack() {
    this.$router.go(-1);
  }

  // 예약번호 복사
  private copyButton() {
    this.$message.success('예약번호가 복사되었습니다.');
    this.$copyText(this.reservationClient.reservationCode);
  }

  private getReserveUrl() {
    const client = this.reservationClient;
    if (client.industryIdx === 1) return { name: 'RoomDetail', params: { roomIdx: client.itemUid } };
    // if (client.industryIdx === 2) return { name: 'ExcitingDetail', params: { idx: client.storeIdx, itemUid: client.itemUid } };
    if (client.industryIdx === 2 || client.industryIdx === 3) return { name: 'LeisureDetail', params: { idx: client.storeIdx, itemUid: client.itemUid } };
    if (client.industryIdx === 4) return { name: 'RestaurantView', params: { idx: client.storeIdx } };
    if (client.industryIdx === 5) return { name: 'GoodsView', params: { uid: client.itemUid } };
    if (client.industryIdx === 6) return { name: 'PlaceDetail', params: { idx: client.storeIdx, itemUid: client.itemUid } };
    return {};
  }

  private handleRetry(client: any) {
    const route: any = this.getReserveUrl();
    this.$router.push(route);
  }

  private handleKakaoShare() {
    const client = this.reservationClient;
    const route: any = this.getReserveUrl();
    const baseUrl = process.env.VUE_APP_TARGET;
    const shareUrl = baseUrl + route.href;
    (window as any).Kakao.cleanup();
    (window as any).Kakao.init(process.env.VUE_APP_KAKAO_APP_KEY);
    (window as any).Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: client.storeName,
        description: client.itemName,
        imageUrl: client.storeThumbUid ? `${baseUrl}/api/attached-file/${client.storeThumbUid}` : `${baseUrl}/favicon.ico`,
        link: {
          mobileWebUrl: shareUrl,
          webUrl: shareUrl,
        },
      },
      social: {
        likeCount: 0,
        commentCount: 0,
        sharedCount: 0,
      },
      buttons: [
        {
          title: `${client.industryIdx === 1 ? '객실 보러가기' : '보러가기'}`,
          link: {
            mobileWebUrl: shareUrl,
            webUrl: shareUrl,
          },
        },
      ],
    });
  }

  // 다이얼로그를 열기 위한 메소드
  private openDialog() {
    this.dialogVisible = true;
    document.documentElement.classList.add('noscr');
    this.getPaymentInfo();
  }

  private closeDialog() {
    document.documentElement.classList.remove('noscr');
  }

  private tossApiLoading = false;

  private getPaymentInfo() {
    this.tossApiLoading = true;
    getPaymentInfo(this.reservationClient.paymentKey).then((res: any) => {
      this.reservationClientInfo = res.data;
      this.tossApiLoading = false;
    }).catch(() => {
      this.$message.error('결제증빙을 찾을 수 없습니다.');
    });
  }
}
